/* eslint-disable max-len */
import React from 'react';
import { generateId } from '../../adapters/utils';

class SVG extends React.PureComponent {
    render() {
        const { title, description, ...props } = this.props;
        const uniqueId = `${generateId()}`;
        const uniqueIdForTitle = `icon-title-${uniqueId}`;
        const uniqueIdForDescription = `icon-description-${uniqueId}`;

        let ariaLabelledBy;
        if (undefined !== title) ariaLabelledBy = uniqueIdForTitle;
        if (undefined !== description) {
            if (undefined !== ariaLabelledBy) {
                ariaLabelledBy += ` ${uniqueIdForDescription}`;
            } else {
                ariaLabelledBy = uniqueIdForDescription;
            }
        }

        return (
            <a href="https://www.telstra.com.au/" className="telstra-logo">
                <svg role="img" {...(ariaLabelledBy ? { 'aria-labelledby': ariaLabelledBy } : {})} viewBox="0 0 24 24" xmlns="http://www.w3.org/2000/svg" {...props}>
                    {undefined !== title ? <title id={uniqueIdForTitle}>{title}</title> : null}
                    {undefined !== description ? <desc id={uniqueIdForDescription}>{description}</desc> : null}
                    <g fill="none" fillRule="evenodd" transform="translate(4 3)">
                        <g transform="translate(0 4)">
                            <mask id="id-48a" fill="#fff" />
                            <path
                                id="LogoBottom"
                                d="M10.472 3.665l-.693 4.193c-.143.754-.624.959-1.052.959H5.52l1.35-7.68C5.524.5 4.147.086 3.05.086c-1.043 0-1.89.29-2.454.988C.198 1.569 0 2.18 0 2.908c0 2.184 1.666 5.213 4.516 7.684C7.058 12.777 9.854 14 11.89 14c1.015 0 1.834-.32 2.371-.96.423-.495.592-1.137.592-1.865 0-2.121-1.678-5.095-4.38-7.51"
                                fill="#F96449"
                            />
                        </g>
                        <path
                            id="LogoTop"
                            d="M3.472.886L3 3.365h4.218L5.408 13h3.354c.448 0 .952-.2 1.102-.939l1.534-8.696h3.034c.535 0 .976-.343 1.094-.883L16 0H4.565c-.533 0-.975.345-1.093.886z"
                            fill="#0D54FF"
                        />
                    </g>
                </svg>
            </a>
        );
    }
}

export default SVG;
