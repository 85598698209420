import './session-timeout.css';
import React from 'react';
import Modal from 'react-modal';
import { withRouter } from 'react-router-dom';
import timeout from '../../assets/img/svg/timeout.svg';
import { redirectToMyTelstra } from '../../adapters/esim-utils';
import { ActionButton, IconButton, Spacing, TextStyle } from '@able/react';
import { getAbleSpriteSheet } from '../../adapters/utils';

// Element setting not needed in testing mode
if (process.env.NODE_ENV !== 'test') {
    Modal.setAppElement('#ppa');
}

const ableSpriteSheet = getAbleSpriteSheet();

export const SessionTimeoutModal = ({ toggleModal, modalOpen, permitSessionRefresh, ...props }) => {
    const {
        appData: {
            uiState: { isEsimActivation }
        }
    } = props;

    const redirectToHomePage = () => (
        // Redirect to My Telstra - eSIM flow
        isEsimActivation ? redirectToMyTelstra() : props.history.go('/')
    );

    const PermittedRefreshContent = () => (
        <div>
            <Spacing left="spacing4x" right="spacing2x" top="spacing4x" className={['modal-header-no-border', 'position-relative']}>
                <IconButton icon="Close" className="session-modal-closebtn" developmentUrl={ableSpriteSheet} onClick={toggleModal} />
                <img className="time-out-image" src={timeout} alt="time out" />
                <TextStyle element="h2" alias="HeadingC" className="mb-3 mt-4">Your session is about to expire</TextStyle>
                <TextStyle alias="TextBodyShort" className="mb-3">Click Continue to keep using this page.</TextStyle>
            </Spacing>
            <div className="modal-footer">
                <ActionButton element="button" variant="LowEmphasis" label="Continue" onClick={toggleModal} className="m-1" />
            </div>
        </div>
    );

    const RefusedRefreshContent = () => (
        <div>
            <Spacing left="spacing4x" right="spacing2x" top="spacing4x" className={['modal-header-no-border', 'position-relative']}>
                <img className="time-out-image" src={timeout} alt="time out" />
                <TextStyle element="h2" alias="HeadingC" className="mb-3 mt-4">Your session has expired</TextStyle>
                <TextStyle alias="TextBodyShort" className="mb-3">Restart to finish activating your SIM.</TextStyle>
                <TextStyle alias="TextBodyShort" className="mb-3">If you have already submitted your activation, there is nothing for you to do.</TextStyle>
            </Spacing>
            <div className="modal-footer">
                <ActionButton element="button" variant="LowEmphasis" label="Restart" onClick={redirectToHomePage} className="m-1" />
            </div>
        </div>
    );

    return (
        <Modal isOpen={modalOpen} contentLabel="Session Timeout Modal" onRequestClose={() => false} className="modal-dialog modal-lg" role="dialog">
            <div className="modal-content">{permitSessionRefresh ? <PermittedRefreshContent /> : <RefusedRefreshContent />}</div>
        </Modal>
    );
};
export default withRouter(SessionTimeoutModal);
